import React from "react";
import "../../../../style/PdfFiles.css";
import { usePdfsContext } from "./../UserInfoDataToView";
import Logo from "../../../../assets/LogoForprint.jpg";
import moment from "moment";

function JoiningRequest() {
  const { studentForm } = usePdfsContext();
  const birthDate = moment(studentForm?.birthDate).format("YYYY/MM/DD");
  return (
    <div className="pdfJoinContainer pdfContainer" dir="rtl">
      <div className="d-flex justify-content-between pt-4 pb-4">
        <div className="logoContainer">
          <img src={Logo} alt="logo" />
        </div>
        <div className="d-flex flex-column align-items-center w-50">
          <div>طلب التحاق بجامعة الأزهر</div>
          <div>(مستجدين)</div>
          <div>للعام الجامعية 2022 / 2023</div>
        </div>
        <div className="d-flex flex-column align-items-center">
          <div className="p-2 toBorder mb-2 w-100 text-center">
            نموزج رقم (27) أ
          </div>
          <div className="p-2 toBorder mb-2 w-100 text-center">0010670</div>
        </div>
      </div>
      <div className="d-flex justify-content-between">
        <div></div>
        <div></div>
        <div className="p-2 toBorder mb-2 text-center">
          رقم الجلوس : {studentForm?.secondarySetCode}
        </div>
      </div>
      <div className="d-flex mt-3">
        <div className="d-flex w-100 justify-content-between">
          <div>
            اسم الطالب ولقبه: {studentForm?.firstName} {studentForm?.fullName}
          </div>
          <div>المعهد: {studentForm?.instituteName}</div>
          <div>المحافظة : {studentForm?.birthCityName}</div>
        </div>
      </div>
      <div className="d-flex mt-3">
        <div className="d-flex w-100 justify-content-between">
          <div>المذهب: {studentForm?.doctrineName}</div>
          <div>مبصر / كفيف: {studentForm?.see}</div>
          <div>الجنسية : {studentForm?.nationalityName}</div>
        </div>
      </div>
      <div className="d-flex mt-3">
        <div className="d-flex w-100 justify-content-between">
          <div>الشهادة الحاصل عليها الطالب:.......................</div>
          <div>القسم:.................</div>
          <div>الدور (اول / ثان) </div>
        </div>
      </div>
      <div className="d-flex mt-3">
        <div className="d-flex w-100 justify-content-between">
          <div>عام الحصول على الشهادة :..........</div>
          <div>
            اللغة الاجنبية الأولى التي درسها الطالب:{" "}
            {studentForm?.secondaryLanguageName}
          </div>
          <div>درجتها : ...........</div>
        </div>
      </div>
      <div className="d-flex mt-3">
        <div className="d-flex w-100 justify-content-between">
          <div></div>
          <div>اللغة الاجنبية الثانية التي درسها الطالب:...............</div>
          <div>درجتها : ...........</div>
        </div>
      </div>
      <div className="d-flex mt-3">
        <div className="d-flex w-100 justify-content-between">
          <div>مجموع الدرجات:...............</div>
          <div>درجة المستوى الخاص : ...........</div>
        </div>
      </div>
      <div className="d-flex mt-3">
        <div className="d-flex w-100 justify-content-between">
          <div>
            {" "}
            مجموع الدرجات بعد اضافة المستوى
            الخاص:...................................................................................
          </div>
        </div>
      </div>
      <div className="d-flex mt-3">
        <div className="d-flex w-100 justify-content-between">
          <div>تاريخ الميلاد : {birthDate}</div>
        </div>
      </div>
      <div className="d-flex mt-3">
        <div className="d-flex w-100 justify-content-between">
          <div className="mt-4 mb-4">
            السن أول سبتمبر من السنة الحالية 2022:...............
          </div>
          <div className="d-flex justify-content-between">
            <div className="d-flex flex-column m-4">
              <div>يوم</div>
              <div>........</div>
            </div>
            <div className="d-flex flex-column m-4">
              <div>شهر</div>
              <div>........</div>
            </div>
            <div className="d-flex flex-column m-4">
              <div>سنة</div>
              <div>........</div>
            </div>
          </div>
        </div>
      </div>
      <div className="d-flex mt-3">
        <div className="d-flex w-100 justify-content-between">
          <div>محل الميلاد : القرية : ...........</div>
          <div>المركز / القسم:...............</div>
          <div>المحافظة : {studentForm?.birthCityName}</div>
        </div>
      </div>
      <div className="d-flex mt-3">
        <div className="d-flex w-100 justify-content-between">
          <div>رقم بطاقة الرقم القومي : ...........</div>
          <div>جهة اصدارها:...............</div>
          <div>تاريخ اصدارها &nbsp; &nbsp; / &nbsp; &nbsp; / &nbsp; &nbsp;</div>
        </div>
      </div>
      <div className="d-flex mt-3">
        <div className="d-flex w-100 justify-content-between">
          <div>رقم جواز السفر : ...........</div>
          <div>جهة اصداره:...............</div>
          <div>تاريخ اصداره &nbsp; &nbsp; / &nbsp; &nbsp; / &nbsp; &nbsp;</div>
        </div>
      </div>
      <div className="d-flex mt-3">
        <div className="d-flex w-100 justify-content-between">
          <div>
            {" "}
            رقم بطاقة الخدمة الوطنية والعسكرية:
            ..................................................................................................
          </div>
        </div>
      </div>
      <div className="d-flex mt-3">
        <div className="d-flex w-100 justify-content-between">
          <div>
            محل سكن الطالب:....................................................
          </div>
          <div>رقم التليفون : {studentForm?.telMobile}</div>
        </div>
      </div>
      <div className="d-flex mt-3">
        <div className="d-flex w-100 justify-content-between">
          <div>
            {" "}
            وظيفة الطالب او الجهة التي يعمل بها (اذا كان موظفا):
            ......................................................................
          </div>
        </div>
      </div>
      <div className="d-flex mt-3">
        <div className="d-flex w-100 justify-content-between">
          <div>اسم ولي امر الطالب: {studentForm?.fatherName}</div>
          <div>
            وظيفته : ....................................................
          </div>
        </div>
      </div>
      <div className="d-flex mt-3">
        <div className="d-flex w-100 justify-content-between">
          <div>
            عنوان ولي امر الطالب:..............................................
          </div>
          <div>رقم التليفون : {studentForm?.fatherMobile}</div>
        </div>
      </div>
      <div className="d-flex mt-5 justify-content-center">
        <div className="d-flex w-100 justify-content-center">
          <div className="ml-4" style={{ fontWeight: 800 }}>
            توقيع الطالب:.........................
          </div>
          <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</div>
          <div className="mr-4" style={{ fontWeight: 800 }}>
            توقيع ولي الامر : .................................
          </div>
        </div>
      </div>
      <hr />
      <div className="d-flex mt-3">
        <div className="d-flex w-100 justify-content-between">
          <div>وقد سدد الطالب / الطالبة الرسوم وقدرها : ...........</div>
          <div>بالقسيمة رقم:...............</div>
          <div>بتاريخ &nbsp; &nbsp; / &nbsp; &nbsp; / &nbsp; &nbsp;</div>
        </div>
      </div>
      <div className="d-flex mt-3">
        <div className="d-flex w-100 justify-content-between">
          <div></div>
          <div></div>
          <div>الموظف المختص : .....................</div>
        </div>
      </div>
    </div>
  );
}

export default JoiningRequest;
