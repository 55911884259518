import React from "react";
import "../../style/UserInfo.css";
import UserDataFileds from "./UserInfoAddFields/UserDataFileds";
import UserCertificateDataFileds from "./UserInfoAddFields/UserCertificateDataFileds";
import UserUniversityInfoFields from "./UserInfoAddFields/UserUniversityInfo";
import UserImagesToUpload from "./UserInfoAddFields/UserImagesToUpload";
import AddUserButton from "../UserInfoData/InputLayout/AddUserButton";
import UserInfoDataToView from "./UserInfoDataToView/UserInfoDataToView";
import { useIsSubmitContext } from "../../layout";
import { useQueryResponseProviderContext } from "./core/QueryReponseProvider";
import Loader from "./../Loader/Loader";

function UserInfoWrapper() {
  const { isSubmited } = useIsSubmitContext();
  const { isLoading, isFetching, isSuccess } =
    useQueryResponseProviderContext();
  return (
    <React.Fragment>
      {!isLoading && !isFetching && isSuccess ? (
        <>
          {!isSubmited && (
            <div>
              <h4 className="UserInfoTitle">المعلومات الشخصية:-</h4>
              <UserDataFileds />
              {/* <hr /> */}
              <h4 className="UserInfoTitle">بيانات الشهادة الثانوية :-</h4>
              <UserCertificateDataFileds />
              {/* <hr /> */}
              <h4 className="UserInfoTitle">
                بيانات الكلية والشعبة المرشح لها :-
              </h4>
              <UserUniversityInfoFields />
              <h4 className="UserInfoTitle">المرفقات والصور:-</h4>
              <UserImagesToUpload />
              {/* <hr /> */}
              <AddUserButton />
            </div>
          )}
        </>
      ) : (
        <Loader />
      )}

      {isSubmited && <UserInfoDataToView />}
    </React.Fragment>
  );
}

export default UserInfoWrapper;
