import React from "react";
import Form from "react-bootstrap/Form";
import SelectInputLayout from "../InputLayout/SelectInputLayout";
import { useQueryResponseProviderContext } from "../core/QueryReponseProvider";
import { ConstraintType } from "../../../json/Json";
function UserUniversityInfoFields() {
    const { dropdownSections, dropDownStage, dropdownStudentStatus } =
      useQueryResponseProviderContext();
  return (
    <Form>
      <SelectInputLayout
        name={"StageId"}
        label={"الفرقة الدراسية:"}
        data={dropDownStage}
        defaultLabel={"الفرقة الاولى"}
        TxtMsg={""}
      />
      <SelectInputLayout
        name={"SectionId"}
        label={"الشعبة المرشح لها:"}
        data={dropdownSections}
        defaultLabel={"التاريخ والحضارة"}
        TxtMsg={
          "الشعبة/ القسم المرشح لها النهائية للكلية موجودة ببطاقة الترشح أو التحويل"
        }
      />
      <SelectInputLayout
        name={"StatusId"}
        data={dropdownStudentStatus}
        label={"حالة القيد:"}
        defaultLabel={"الفرقة الاولى"}
        TxtMsg={""}
      />
      <SelectInputLayout
        name={"IsRegular"}
        label={"نوع القيد"}
        data={ConstraintType}
        defaultLabel={"الفرقة الاولى"}
        TxtMsg={""}
      />
      {/* <SelectInputLayout
        name={"roleOfSuccess"}
        label={"الفرقة الدراسية:"}
        defaultLabel={"الفرقة الاولى"}
        TxtMsg={""}

      /> */}
    </Form>
  );
}

export default UserUniversityInfoFields;
