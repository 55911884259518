const InstructionsData = [
  { item: "أصل شهادة الثانوية الأزهرية + 3 صور منها." },
  {
    item: " أصل شهادة الميلاد مميكنة حديثة بها الرقم الثلاثي للطالب + 3 صور منها.",
  },
  { item: "بطاقة (6 جند) من مكتب التجنيد التابع له الطالب." },
  { item: "نموذج (2 جند) من مديرية الأمن التابع لها الطالب." },
  {
    item: "عدد (6) صور شخصية حديثة 4×6 ويكتب الطالب اسمه كاملًا على كل صورة مع ضرورة أن تكون خلفية الصورة بيضاء تمامًا.",
  },
  { item: "عدد (3) صورة لبطاقة الرقم القومي." },
  {
    item: " بطاقة ترشيح الطالب للكلية من موقع بوابة الحكومة المصرية + 3 صور منها.",
  },
  { item: "استمارة طلب الالتحاق (عبر المنصة)" },
  { item: "استمارة الكشف الطبي (عبر المنصة)" },
  { item: "حافظة بلاستيك لحفظ الأوراق." },
];
const RoleOfSuccess = [
  { label: "الدور الأول", value: "الدور الأول" },
  { label: "الدور الثاني", value: "الدور الثاني" },
];
const AcademicQualification = [
  { label: "ادبي", value: "ادبي" },
  { label: "علمي", value: "علمي" },
];
const ConstraintType = [
  { label: "انتظام", value: "true" },
  { label: "انتساب", value: "false" },
];
export {
  InstructionsData,
  RoleOfSuccess,
  AcademicQualification,
  ConstraintType,
};
