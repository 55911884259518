import React from "react";
import Form from "react-bootstrap/Form";
import { useQueryResponseProviderContext } from "../core/QueryReponseProvider";
import InputLayoutEl from "../InputLayout/InputLayoutEl";
import RadioInputLayout from "../InputLayout/RadioInputLayout";
import SelectInputLayout from "../InputLayout/SelectInputLayout";
import moment from "moment";
function UserDataFileds() {
  const { dropDownNationality, dropDownCity } =
    useQueryResponseProviderContext();
  const date = moment(new Date(), "YYYY/MM/DD");
  const month = date.format("M");
  const monthDays = moment(
    moment(new Date()).subtract(1, "years").subtract(month, "months"),
    "YYYY-MM"
  ).daysInMonth();
  const currDays = Math.abs(date.format("D") - monthDays);
  const birthDateMax = moment(new Date())
    .subtract(1, "years")
    .subtract(month, "months")
    .add(currDays, "days")
    .format("YYYY-MM-DD");
  const IDExpireDateMax = moment(new Date())
    .add(10, "years")
    .format("YYYY-MM-DD");
  return (
    <Form>
      <InputLayoutEl
        name="FullName"
        label={"الاسم بالكامل:"}
        TxtMsg={"اكتب اسمك كامًلا مع الهمزات أو النقاط مطابقًا لشهادة الميلاد"}
        placeholder={"الاسم بالكامل"}
      />
      <InputLayoutEl
        name="FirstName"
        label={"الاسم الأول:"}
        TxtMsg={"اسمك فقط"}
        placeholder={"الاسم الأول"}
      />
      <InputLayoutEl
        name="FatherName"
        label={"اسم الوالد:"}
        TxtMsg={"اسم الوالد كاملًا مع الهمزات أو النقاط مطابقًا لشهادة الميلاد"}
        placeholder={"اسم الوالد"}
      />
      <InputLayoutEl
        name="IdentityNo"
        label={"الرقم القومي:"}
        TxtMsg={
          "في حالة لا يوجد بطاقة رقم قومي يمكن كتابة رقم القومي في شهادة الميلاد"
        }
        placeholder={"الرقم القومي"}
      />
      <SelectInputLayout
        name={"NationalityId"}
        data={dropDownNationality}
        label={"الجنسية"}
        defaultLabel={"الجنسية"}
      />
      <InputLayoutEl
        name="BirthDate"
        label={"تاريخ الميلاد:"}
        TxtMsg={""}
        maxDate={birthDateMax}
        inputType={"date"}
      />
      <SelectInputLayout
        name={"BirthCountryId"}
        data={dropDownCity}
        label={"محل الميلاد:"}
        defaultLabel={"اختر المحافظة"}
        TxtMsg={"المحافظة التي ولدت بها"}
      />
      {/* <SelectInputLayout
        name={"department"}
        label={"المركز/القسم:"}
        defaultLabel={"اختر المركز"}
        TxtMsg={"قسم الشرطة التابع له موجود في البطاقة بجوار العنوان"}
      /> */}
      <InputLayoutEl
        name="IdentityExpiredDate"
        label={"تاريخ صدور الرقم القومي"}
        TxtMsg={"موجود في ظهر بطاقة الرقم القومي بهذا الشكل 2018/06"}
        placeholder={"2018/06"}
        maxDate={IDExpireDateMax}
        inputType={"date"}
      />
      <InputLayoutEl
        name="TelMobile"
        label={"رقم الهاتف:"}
        TxtMsg={""}
        placeholder={"رقم الهاتف"}
      />
      <InputLayoutEl
        name="FatherMobile"
        label={"رقم ولي الأمر"}
        TxtMsg={""}
        placeholder={"رقم ولي الأمر"}
      />
      <InputLayoutEl
        name="AddressHome"
        label={"العنوان"}
        TxtMsg={""}
        layout={"textarea"}
        placeholder={"اكتب العنوان الحالي"}
      />
      <RadioInputLayout
        name="IsFatherDead"
        options={["لا", "نعم"]}
        value="لا"
        label={"هل ولي الأمر متوفى"}
      />
      <RadioInputLayout
        name="IsSee"
        options={["مبصر", "كفيف"]}
        value="مبصر"
        label={"الرؤية"}
      />
      <RadioInputLayout
        name="IsSpecialNeeds"
        options={["لا", "نعم"]}
        value="لا"
        label={"هل أنت من ذوي الإعاقة"}
        TxtMsg={"الإعاقات - الأمراض المزمنة - الإصابات"}
      />
    </Form>
  );
}

export default UserDataFileds;
