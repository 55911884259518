import "./style/App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { StylesProvider } from "@mui/styles";
import { LayoutContainer } from "./layout";
import { QueryClient, QueryClientProvider } from "react-query";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const queryClient = new QueryClient();
function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <StylesProvider injectFirst>
        <div className="App">
          <div className="LayoutMainContainer">
            <LayoutContainer />
            <ToastContainer />
          </div>
        </div>
      </StylesProvider>
    </QueryClientProvider>
  );
}

export default App;
