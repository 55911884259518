/* eslint-disable react-hooks/rules-of-hooks */
import React, { useContext, createContext } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useIsSubmitContext } from "../../../layout";
import { sendUserData } from "../core/_request";
import { toast } from "react-toastify";

const FormContext = createContext();
export const useFormsContext = () => useContext(FormContext);
function FormsHookContext({ children }) {
  const { setIsSubmited, userId, setCurrentUser } = useIsSubmitContext();
  const userSchema = yup
    .object({
      FullName: yup.string().required("هذا الحقل الزامي"),
      FirstName: yup.string().required("هذا الحقل الزامي"),
      FatherName: yup.string().required("هذا الحقل الزامي"),
      NationalityId: yup.string().required("هذا الحقل الزامي"),
      IdentityNo: yup.number().when("NationalityId", {
        is: (value) => value && value == 1,
        then: yup
          .number()
          .typeError("يجب اضافة اربعة عشر رقما فقط")
          .min(10000000000000, "لا يمكن اضافة اقل من اربعة عشر رقم")
          .max(99999999999999, "لا يمكن اضافة اكثر من اربعة عشر رقم")
          .required("هذا الحقل الزامي"),
        otherwise: yup
          .number()
          .typeError("يجب اضافة اربعة عشر رقما فقط")
          .min(10000, "لا يمكن اضافة اقل من خمسة ارقام")
          .max(999999999, "لا يمكن اضافة اكثر من تسعة ارقام")
          .required("هذا الحقل الزامي"),
      }),
      BirthDate: yup.string().required("هذا الحقل الزامي"),
      BirthCountryId: yup.string().required("هذا الحقل الزامي"),
      BirthCityId: yup.string().required("هذا الحقل الزامي"),
      IdentityExpiredDate: yup.string().required("هذا الحقل الزامي"),
      TelMobile: yup
        .number()
        .typeError("يجب اضافة احدا عشر رقما فقط")
        .min(1000000000, "لا يمكن اضافة اقل من احدى عشر رقما")
        .max(9999999999, "لا يمكن اضافة اكثر من احدى عشر رقما")
        .required("هذا الحقل الزامي"),
      FatherMobile: yup
        .number()
        .typeError("يجب اضافة احدا عشر رقما فقط")
        .min(1000000000, "لا يمكن اضافة اقل من احدى عشر رقما")
        .max(9999999999, "لا يمكن اضافة اكثر من احدى عشر رقما")
        .required("هذا الحقل الزامي"),
      AddressHome: yup.string().required("هذا الحقل الزامي"),
      IsFatherDead: yup.string().required("هذا الحقل الزامي"),
      IsSee: yup.string().required("هذا الحقل الزامي"),
      IsSpecialNeeds: yup.string().required("هذا الحقل الزامي"),
      SecondarySetCode: yup.string().required("هذا الحقل الزامي"),
      StageSuccessful: yup.string().required("هذا الحقل الزامي"),
      DoctrineId: yup.string().required("هذا الحقل الزامي"),
      SecondaryLanguageId: yup.string().required("هذا الحقل الزامي"),
      SecondarySection: yup.string().required("هذا الحقل الزامي"),
      InstituteName: yup.string().required("هذا الحقل الزامي"),
      QualifyYear: yup.string().required("هذا الحقل الزامي"),
      RegisterYear: yup.string().required("هذا الحقل الزامي"),
      SecondaryTotalGrade: yup.string().required("هذا الحقل الزامي"),
      StageId: yup.string().required("هذا الحقل الزامي"),
      SectionId: yup.string().required("هذا الحقل الزامي"),
      StatusId: yup.string().required("هذا الحقل الزامي"),
      FacultyId: yup.string().required("هذا الحقل الزامي"),
      IsRegular: yup.boolean().required("هذا الحقل الزامي"),
      photo: yup
        .mixed()
        .transform((file) => (!file ? undefined : file))
        .typeError("")
        .required("هذا الحقل الزامي و الملفات يجب ان تكون jpg او jpeg او png"),
      NationalIdPhoto: yup
        .mixed()
        .transform((file) => (!file ? undefined : file))
        .required("هذا الحقل الزامي و الملفات يجب ان تكون jpg او jpeg او png"),
      BirthCertificatePhoto: yup
        .mixed()
        .transform((file) => (!file ? undefined : file))
        .required("هذا الحقل الزامي و الملفات يجب ان تكون jpg او jpeg او png"),
      HighSchoolDiplomaPhoto: yup
        .mixed()
        .transform((file) => (!file ? undefined : file))
        .required("هذا الحقل الزامي و الملفات يجب ان تكون jpg او jpeg او png"),
      NominationCardPhoto: yup
        .mixed()
        .transform((file) => (!file ? undefined : file))
        .required("هذا الحقل الزامي و الملفات يجب ان تكون jpg او jpeg او png"),
      PaymentReceiptPhoto: yup
        .mixed()
        .transform((file) => (!file ? undefined : file))
        .required("هذا الحقل الزامي و الملفات يجب ان تكون jpg او jpeg او png"),
    })
    .required();
  const {
    register,
    handleSubmit,
    watch,
    control,
    setValue,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      FullName: "",
      FirstName: "",
      FatherName: "",
      IdentityNo: "",
      NationalityId: "1",
      BirthDate: "",
      BirthCountryId: "1",
      BirthCityId: "1",
      IdentityExpiredDate: "",
      TelMobile: "",
      FatherMobile: "",
      AddressHome: "",
      IsFatherDead: false,
      IsSee: true,
      IsSpecialNeeds: false,
      SecondarySetCode: "",
      StageSuccessful: "الدور الأول",
      DoctrineId: "1",
      SecondaryLanguageId: "1",
      SecondarySection: "ادبي",
      InstituteName: "",
      QualifyYear: "2021",
      RegisterYear: "2021",
      SecondaryTotalGrade: "",
      StageId: "0",
      SectionId: "1",
      StatusId: "1",
      FacultyId: "1",
      IsRegular: true,
      photo: "",
      NationalIdPhoto: "",
      BirthCertificatePhoto: "",
      HighSchoolDiplomaPhoto: "",
      NominationCardPhoto: "",
      PaymentReceiptPhoto: "",
    },
    mode: "all",
    resolver: yupResolver(userSchema),
  });
  const handleSubmitData = () => {
    const formData = new FormData();
    for (const [key, value] of Object.entries(watch())) {
      formData.append(key, value);
    }
    handleSubmit((data) => {
      if (data) {
        sendUserData(formData).then((res) => {
          if (res.data == "User_already_exists") {
            toast.warning(
              "هذه البيانات موجودة بالفعل برجاء التأكد ان البيانات جديدة"
            );
          } else if (res.data.studentsId) {
            setCurrentUser(res.data);
            toast.success("تم التسجيل بنجاح");
            setIsSubmited(true);
            reset();
          } else {
            toast.error("لقد حدث خطأ ما");
          }
        });
      }
    })();
  };

  return (
    <FormContext.Provider
      value={{
        register,
        handleSubmitData,
        watch,
        control,
        errors,
        reset,
        setValue,
      }}
    >
      {children}
    </FormContext.Provider>
  );
}

export default FormsHookContext;
