import React, { useState } from "react";
import "../../style/TogglerContainer.css";
import styled from "styled-components";
import Button from "@mui/material/Button";
import { Layers } from "@styled-icons/feather/Layers";
import { DocumentSearch } from "@styled-icons/heroicons-outline/DocumentSearch";
import { useIsSubmitContext } from "../../layout";
const LayerGroupIcon = styled(Layers)`
  width: 25px;
`;
const DocumentSearchIcon = styled(DocumentSearch)`
  width: 25px;
`;
function TogglerContainer() {
  const { isToEdit, setIsToEdit } = useIsSubmitContext();
  const handleAddUser = () => {
    setIsToEdit(false);
  };
  const handleEditUser = () => {
    setIsToEdit(true);
  };
  return (
    <div className="TogglerContainer">
      <Button
        variant="text"
        className={`${!isToEdit ? "active" : ""} item`}
        onClick={handleAddUser}
      >
        <div>
          <LayerGroupIcon />
        </div>
        <div>تقديم جديد</div>
      </Button>
      <Button
        variant="text"
        className={`${isToEdit ? "active" : ""} item`}
        onClick={handleEditUser}
      >
        <div>
          <DocumentSearchIcon />
        </div>
        <div>الاستعلام أو التعديل</div>
      </Button>
    </div>
  );
}

export default TogglerContainer;
