import React , {useMemo}from "react";
import Form from "react-bootstrap/Form";
import InputLayoutEl from "../InputLayout/InputLayoutEl";
import SelectInputLayout from "../InputLayout/SelectInputLayout";
import { useQueryResponseProviderContext } from "../core/QueryReponseProvider";
import { RoleOfSuccess, AcademicQualification } from "../../../json/Json";
function UserCertificateDataFileds() {
  const { dropDownDoctrine, dropDownLanguages, dropDownAcademicYear } =
    useQueryResponseProviderContext();
    return (
    <Form>
      <InputLayoutEl
        name="SecondarySetCode"
        label={"رقم جلوس الثانوية"}
        TxtMsg={"رقم الجلوس المكتوب علي شهادة الثانوية"}
        placeholder={"رقم جلوس الثانوية"}
      />
      <SelectInputLayout
        name={"StageSuccessful"}
        label={"دور النجاح:"}
        data={RoleOfSuccess}
        defaultLabel={"الدور الأول"}
        TxtMsg={"دور النجاح في الثانوية ناجح من الدور الأول أو الثاني"}
      />
      <SelectInputLayout
        name={"DoctrineId"}
        data={dropDownDoctrine}
        label={"المذهب:"}
        defaultLabel={"الحنفي"}
        TxtMsg={"المذهب الفقهي موجود بالشهادة الثانوية"}
      />
      <SelectInputLayout
        name={"SecondaryLanguageId"}
        data={dropDownLanguages}
        label={"اللغة الأجنبية:"}
        defaultLabel={"اللغة الانجليزية"}
        TxtMsg={"اللغة الأجنبية موجودة بالشهادةالثانوية"}
      />
      <SelectInputLayout
        name={"SecondarySection"}
        data={AcademicQualification}
        label={"المؤهل الدراسي:"}
        defaultLabel={"ادبي"}
      />
      <InputLayoutEl
        name="InstituteName"
        label={"اسم المعهد"}
        TxtMsg={"اسم المدرسة الثانوية/المعهد الثانوي الاسم بالكامل"}
        placeholder={"معهد / محمد رجب"}
      />
      <SelectInputLayout
        name={"QualifyYear"}
        data={dropDownAcademicYear}
        label={"سنة الحصول علي الشهادة"}
        defaultLabel={"2022-2021"}
        TxtMsg={"موجودة في أعلى شهادة الثانوية سنة الحصول"}
      />
      <SelectInputLayout
        name={"RegisterYear"}
        data={dropDownAcademicYear}
        label={"سنة التقديم في الكلية"}
        defaultLabel={"2023-2021"}
      />
      <InputLayoutEl
        name="SecondaryTotalGrade"
        label={"مجموع درجات الثانوية"}
        TxtMsg={"درجات شهادة الثانوية"}
        placeholder={"456"}
      />
    </Form>
  );
}

export default UserCertificateDataFileds;
