import React from "react";
import UserInfoWrapperContainer from "./UserInfoWrapperContainer";
import FormsHookContext from "./formsHookContext/formsHookContext";
import "../../style/UserInfo.css";
function UserInfoContainer() {
  return (
    <FormsHookContext>
      <UserInfoWrapperContainer />
    </FormsHookContext>
  );
}

export default UserInfoContainer;
