import React from "react";
import "../../style/UserInfo.css";
import UserInfoWrapper from "./UserInfoWrapper";
import FormsHookContext from "./formsHookContext/formsHookContext";
import QueryReponseProvider from "./core/QueryReponseProvider";
function UserInfoWrapperContainer() {
  return (
    <QueryReponseProvider>
      <FormsHookContext>
        <UserInfoWrapper />
      </FormsHookContext>
    </QueryReponseProvider>
  );
}

export default UserInfoWrapperContainer;
