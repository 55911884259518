import React from "react";
import "../../../style/UserInfo.css";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Controller } from "react-hook-form";
import { useFormsContext } from "../formsHookContext/formsHookContext";
function RadioInputLayout({ label, TxtMsg, value, name, options }) {
  const { control, setValue } = useFormsContext();
  return (
    <Row className="UserInputContainer mt-5 mb-5">
      <Col xs={12} sm={12} md={2} lg={2}>
        {" "}
        <Form.Label> {label}</Form.Label>
      </Col>
      <Col xs={12} sm={12} md={6} lg={6} dir="rtl">
        {name === "IsSee" ? (
          <Controller
            control={control}
            name={name}
            defaultValue={value}
            render={({ field: { onChange, ...props } }) => (
              <div className="d-flex justify-content-evenly">
                <div>
                  <Form.Check
                    type={"radio"}
                    {...props}
                    onChange={() => setValue(name, true)}
                    defaultChecked
                    value={options[0]}
                    label={options[0]}
                  />
                </div>
                <div>
                  {" "}
                  <Form.Check
                    type={"radio"}
                    {...props}
                    onChange={() => setValue(name, false)}
                    value={options[1]}
                    label={options[1]}
                  />
                </div>
              </div>
            )}
          />
        ) : (
          <Controller
            control={control}
            name={name}
            defaultValue={value}
            render={({ field: { onChange, ...props } }) => (
              <div className="d-flex justify-content-evenly">
                <div>
                  <Form.Check
                    type={"radio"}
                    {...props}
                    onChange={() => setValue(name, false)}
                    defaultChecked
                    value={options[0]}
                    label={options[0]}
                  />
                </div>
                <div>
                  {" "}
                  <Form.Check
                    type={"radio"}
                    {...props}
                    onChange={() => setValue(name, true)}
                    value={options[1]}
                    label={options[1]}
                  />
                </div>
              </div>
            )}
          />
        )}
      </Col>
      <Col xs={12} sm={12} md={4} lg={4}>
        <span className="textMsg">{TxtMsg}</span>
      </Col>
    </Row>
  );
}

export default RadioInputLayout;
