import React, { useEffect, useState, useContext, createContext } from "react";
import UserInfoSectionLayout from "./UserInfoSectionLayout";
import { useIsSubmitContext } from "./../../../layout/LayoutContainer";
import axios from "axios";
const PdfsContext = createContext();
export const usePdfsContext = () => useContext(PdfsContext);
function UserInfoDataToView() {
  const API_URL = process.env.REACT_APP_API_URL;
  const { currentUser } = useIsSubmitContext();
  const [mediacalData, setMediacalData] = useState();
  const [studentForm, setStudentForm] = useState();
  useEffect(() => {
    axios
      .get(
        `${API_URL}/Students/GetStudentMedicalById/${currentUser?.studentsId}`
      )
      .then((response) => {
        setMediacalData(response?.data);
      });
    axios
      .get(
        `${API_URL}/Students/GetAllDataStudentForm/${currentUser?.studentsId}`
      )
      .then((response) => {
        setStudentForm(response?.data);
      });
    return () => {};
  }, [currentUser.studentsId]);

  return (
    <PdfsContext.Provider value={{ mediacalData, studentForm }}>
      <div dir="rtl">
        <h4 className="UserInfoTitle">المعلومات</h4>
        <UserInfoSectionLayout
          type={"الاسم"}
          name={currentUser?.fullName}
          toPrint={false}
        />
        <hr />
        <UserInfoSectionLayout
          type={"الرقم القومي"}
          name={currentUser?.identityNo}
          toPrint={false}
        />
        <hr />
        <UserInfoSectionLayout
          type={"الشعبة"}
          name={currentUser?.facultyName}
          toPrint={false}
        />
        <hr />
        <UserInfoSectionLayout
          type={"نوع القيد"}
          name={currentUser?.reguler}
          toPrint={false}
        />
        <hr />
        {currentUser?.reguler == "انتظام" && (
          <UserInfoSectionLayout
            type={"المصاريف الدراسية"}
            name={currentUser?.facultyFees?.feesReguler}
            toPrint={false}
          />
        )}
        {currentUser?.reguler == "انتساب" && (
          <UserInfoSectionLayout
            type={"المصاريف الدراسية"}
            name={currentUser?.facultyFees?.feesNotReguler}
            toPrint={false}
          />
        )}

        {/* <hr /> */}
        <UserInfoSectionLayout
          type={"طباعة استمارة الكشف الطبي"}
          name={"طباعة استمارة الكشف الطبي"}
          toPrint={true}
          order={0}
        />
        <hr />
        <UserInfoSectionLayout
          type={"طباعة استمارة طلب الالتحاق"}
          name={"طباعة استمارة طلب الالتحاق"}
          toPrint={true}
          order={1}
        />
      </div>
    </PdfsContext.Provider>
  );
}

export default UserInfoDataToView;
