import React from "react";
import "../../style/InfoContainer.css"
function InfoContainer() {
  return (
    <div>
      <h3 className="headTitle">التنسيق الداخلي</h3>
    </div>
  );
}

export default InfoContainer;
