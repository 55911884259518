import axios from "axios";
const API_URL = process.env.REACT_APP_API_URL;
const getDropdownCity = async () => {
  return await axios.get(`${API_URL}/Students/GetDropdownCity`).then(
    (response) => response,
    (err) => console.log(err)
  );
};
const getDropdownCountry = async () => {
  return await axios
    .get(`${API_URL}/Students/GetDropdownCountry`)
    .then((response) => response);
};
const getDropdownDoctrine = async () => {
  return await axios
    .get(`${API_URL}/Students/GetDropdownDoctrine`)
    .then((response) => response);
};
const getDropdownNationality = async () => {
  return await axios
    .get(`${API_URL}/Students/GetDropdownNationality`)
    .then((response) => response);
};
const getDropdownStage = async () => {
  return await axios
    .get(`${API_URL}/Students/GetDropdownStage`)
    .then((response) => response);
};
const getDropdownLanguages = async () => {
  return await axios
    .get(`${API_URL}/Students/GetDropdownLanguages`)
    .then((response) => response);
};
const getDropdownAcademicYear = async () => {
  return await axios
    .get(`${API_URL}/Students/GetDropdownAcademicYear`)
    .then((response) => response);
};
const getDropdownSections = async () => {
  return await axios
    .get(`${API_URL}/Students/GetDropdownSections`)
    .then((response) => response);
};
const getDropdownStudentStatus = async () => {
  return await axios
    .get(`${API_URL}/Students/GetDropdownStudentStatus`)
    .then((response) => response);
};
const sendUserData = async (formData) => {
  return await axios({
    method: "POST",
    url: `${API_URL}/Students/AddStudent`,
    data: formData,
    headers: { "Content-Type": "multipart/form-data" },
  }).then(
    (res) => {
      return res;
    },
    (err) => {
      console.log("error", err);
    }
  );
};

export {
  getDropdownCity,
  getDropdownCountry,
  getDropdownDoctrine,
  getDropdownNationality,
  getDropdownStage,
  getDropdownLanguages,
  getDropdownAcademicYear,
  getDropdownSections,
  getDropdownStudentStatus,
  sendUserData,
};
