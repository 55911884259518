import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import { Button } from "@mui/material";
import "../../style/UserInfo.css";
import { useIsSubmitContext } from "../../layout";
function EditUserInfoContainer() {
  const { setIsToEdit, setUserID } = useIsSubmitContext();
  const [userInfoToEdit, setuserInfoToEdit] = useState({
    userIdNumber: "",
    userPhoneNumber: "",
    userIdNumberErrorMsg: "",
    userPhoneNumberErrorMsg: "",
  });
  const handleSubmit = () => {
    if (
      userInfoToEdit.userIdNumber == "" &&
      userInfoToEdit.userPhoneNumber == ""
    ) {
      setuserInfoToEdit({
        ...userInfoToEdit,
        userIdNumberErrorMsg: "Please Fill This Field",
        userPhoneNumberErrorMsg: "Please Fill This Field",
      });
    } else if (userInfoToEdit.userIdNumber == "") {
      setuserInfoToEdit({
        ...userInfoToEdit,
        userIdNumberErrorMsg: "Please Fill This Field",
      });
    } else if (userInfoToEdit.userPhoneNumber == "") {
      setuserInfoToEdit({
        ...userInfoToEdit,
        userPhoneNumberErrorMsg: "Please Fill This Field",
      });
    } else {
      setIsToEdit(false);
      setUserID(1);
    }
  };

  return (
    <div dir="rtl">
      <h6 className="mt-3">البحث عن حالة الطالب أو تعديل البيانات</h6>
      <div className="mt-5 mb-5 toEditContainer">
        <div className="">
          <Form.Control
            dir="rtl"
            value={userInfoToEdit.userIdNumber}
            placeholder="رقم البطاقة"
            onChange={(e) =>
              setuserInfoToEdit({
                ...userInfoToEdit,
                userIdNumber: e.target.value,
                userIdNumberErrorMsg: "",
              })
            }
          />
          <p className="errorMsg">{userInfoToEdit.userIdNumberErrorMsg}</p>
        </div>
        <div className="">
          <Form.Control
            dir="rtl"
            value={userInfoToEdit.userPhoneNumber}
            placeholder="رقم الهاتف"
            onChange={(e) =>
              setuserInfoToEdit({
                ...userInfoToEdit,
                userPhoneNumber: e.target.value,
                userPhoneNumberErrorMsg: "",
              })
            }
          />
          <p className="errorMsg">{userInfoToEdit.userPhoneNumberErrorMsg}</p>
        </div>
      </div>
      <div className="w-100 text-center">
        <Button className="addBtn" onClick={handleSubmit}>
          عرض \ تعديل
        </Button>
      </div>
    </div>
  );
}

export default EditUserInfoContainer;
