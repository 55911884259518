import React from "react";
import "../../../style/UserInfo.css";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Controller } from "react-hook-form";
import { useFormsContext } from "../formsHookContext/formsHookContext";
import { ErrorMessage } from "@hookform/error-message";
import Select from "react-select";
function SelectInputLayout({ label, TxtMsg, name, data }) {
  const { control, errors } = useFormsContext();

  const options = data.map((item) => {
    return {
      value: item.value,
      label: item.label,
    };
  });
  return (
    <Row className="UserInputContainer mt-5 mb-5">
      <Col xs={12} sm={12} md={2} lg={2}>
        {" "}
        <Form.Label>{label}</Form.Label>
      </Col>
      <Col xs={12} sm={12} md={6} lg={6} dir="rtl">
        <Controller
          control={control}
          name={name}
          render={({ field: { onChange, onBlur, name } }) => (
            <Select
              multiple
              options={options}
              dir="rtl"
              name={name}
              defaultValue={options[0]}
              onBlur={onBlur}
              onChange={(e) => onChange(e.value)}
            />
          )}
        />
        <ErrorMessage
          errors={errors}
          name={name || ""}
          render={({ message }) => <p className="errorMsg">{message}</p>}
        />
      </Col>
      <Col xs={12} sm={12} md={4} lg={4}>
        <span className="textMsg">{TxtMsg}</span>
      </Col>
    </Row>
  );
}

export default SelectInputLayout;
