import React from "react";
import { Button } from "@mui/material";
import { useFormsContext } from "../formsHookContext/formsHookContext";
function AddUserButton() {
  const { handleSubmitData } = useFormsContext();
  return (
    <Button className="addBtn" onClick={handleSubmitData}>
      تقديم
    </Button>
  );
}

export default AddUserButton;
