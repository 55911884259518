import React, { createContext, useContext, useState } from "react";
import { useQuery } from "react-query";
import {
  getDropdownCity,
  getDropdownCountry,
  getDropdownDoctrine,
  getDropdownNationality,
  getDropdownStage,
  getDropdownLanguages,
  getDropdownAcademicYear,
  getDropdownSections,
  getDropdownStudentStatus,
} from "./_request";
const QueryResponseProviderContext = createContext();
export const useQueryResponseProviderContext = () =>
  useContext(QueryResponseProviderContext);
function QueryReponseProvider({ children }) {
  const [dropDownCity, setDropDownCity] = useState([]);
  const [dropDownCountry, setDropDownCountry] = useState([]);
  const [dropDownDoctrine, setDropDownDoctrine] = useState([]);
  const [dropDownNationality, setDropdownNationality] = useState([]);
  const [dropDownStage, setDropDownStage] = useState([]);
  const [dropDownLanguages, setDropdownLanguages] = useState([]);
  const [dropDownAcademicYear, setDropDownAcademicYear] = useState([]);
  const [dropdownSections, setDropdownSections] = useState([]);
  const [dropdownStudentStatus, setDropdownStudentStatus] = useState([]);
  const { isLoading, error, isFetching, isSuccess } = useQuery(
    ["repoData"],
    async () => {
      const dropDownCity = (await getDropdownCity()).data;
      setDropDownCity(dropDownCity);
      const dropDownCountry = (await getDropdownCountry()).data;
      setDropDownCountry(dropDownCountry);
      const dropDownDoctrine = (await getDropdownDoctrine()).data;
      setDropDownDoctrine(dropDownDoctrine);
      const dropDownNationality = (await getDropdownNationality()).data;
      setDropdownNationality(dropDownNationality);
      const dropDownStage = (await getDropdownStage()).data;
      setDropDownStage(dropDownStage);
      const dropDownLanguages = (await getDropdownLanguages()).data;
      setDropdownLanguages(dropDownLanguages);
      const dropDownAcademicYear = (await getDropdownAcademicYear()).data;
      setDropDownAcademicYear(dropDownAcademicYear);
      const dropdownSections = (await getDropdownSections()).data;
      setDropdownSections(dropdownSections);
      const dropdownStudentStatus = (await getDropdownStudentStatus()).data;
      setDropdownStudentStatus(dropdownStudentStatus);
    },
    {
      refetchOnWindowFocus: false,
    }
  );
  return (
    <QueryResponseProviderContext.Provider
      value={{
        dropDownCity,
        dropDownCountry,
        dropDownDoctrine,
        dropDownNationality,
        dropDownStage,
        dropDownLanguages,
        dropDownAcademicYear,
        dropdownSections,
        dropdownStudentStatus,
        isLoading,
        isFetching,
        isSuccess,
        error,
      }}
    >
      {children}
    </QueryResponseProviderContext.Provider>
  );
}

export default QueryReponseProvider;
