import React from "react";
import "../../../style/UserInfo.css";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import InputGroup from "react-bootstrap/InputGroup";
import { Controller } from "react-hook-form";
import { useFormsContext } from "../formsHookContext/formsHookContext";
import { ErrorMessage } from "@hookform/error-message";
import styled from "styled-components";
import { Edit } from "@styled-icons/boxicons-solid/Edit";
const EditIcon = styled(Edit)`
  width: 25px;
  color: #5a5858;
`;
function InputLayoutEl({
  label,
  TxtMsg,
  placeholder,
  layout,
  inputType,
  name,
  accepted,
  maxDate
}) {
  const { control, errors } = useFormsContext();
  return (
    <Row className="UserInputContainer mt-5 mb-5">
      <Col xs={12} sm={12} md={2} lg={2}>
        {" "}
        <Form.Label> {label}</Form.Label>
      </Col>
      <Col xs={12} sm={12} md={6} lg={6} dir="ltr">
        <InputGroup className="InpuGroup">
          {layout ? (
            <Controller
              name={name || ""}
              control={control}
              render={({ field }) => (
                <Form.Control
                  {...field}
                  dir="rtl"
                  as={layout}
                  rows={3}
                  placeholder={placeholder}
                />
              )}
            />
          ) : (
            <Controller
              name={name || ""}
              control={control}
              render={({ field }) => (
                <Form.Control
                  {...field}
                  dir="rtl"
                  type={inputType}
                  max={maxDate}
                  accept={accepted}
                  placeholder={placeholder}
                />
              )}
            />
          )}
          {inputType !== "file" && (
            <InputGroup.Text>
              <EditIcon />
            </InputGroup.Text>
          )}
        </InputGroup>
        <ErrorMessage
          errors={errors}
          name={name || ""}
          render={({ message }) => <p className="errorMsg">{message}</p>}
        />
      </Col>
      <Col xs={12} sm={12} md={4} lg={4}>
        {" "}
        <span className="textMsg">{TxtMsg}</span>
      </Col>
    </Row>
  );
}

export default InputLayoutEl;
