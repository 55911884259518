import React from "react";
import Form from "react-bootstrap/Form";
import "react-dropzone-uploader/dist/styles.css";
import "../../../style/UserInfo.css";
import DropZoneContainer from "../InputLayout/DropZoneContainer"
function UserImagesToUpload() {
  return (
    <Form className="d-flex w-100 flex-column justify-content-start">
      <DropZoneContainer
        name="photo"
        accepted="image/*"
        label={"صورة شخصية"}
        TxtMsg={"صورة شخصية حديثة مقاس 4*6 صورة استوديو"}
        inputType={"file"}
      />
      <DropZoneContainer
        name="NationalIdPhoto"
        accepted="image/*"
        label={"صورة الرقم القومي"}
        TxtMsg={"صورة الوجه الأول من بطاقة الرقم القومي"}
        inputType={"file"}
      />
      <DropZoneContainer
        name="BirthCertificatePhoto"
        accepted="image/*"
        label={"صورة شهادة الميلاد"}
        TxtMsg={"صورة شهاد ميلاد كمبيوتر حديثة"}
        inputType={"file"}
      />
      <DropZoneContainer
        name="HighSchoolDiplomaPhoto"
        accepted="image/*"
        label={"صورة شهادة الثانوية"}
        TxtMsg={"صورة شهادة الثانوية"}
        inputType={"file"}
      />
      <DropZoneContainer
        name="NominationCardPhoto"
        accepted="image/*"
        label={"صورة بطاقة الترشيح"}
        TxtMsg={"صورة بطاقة الترشيح أو بطاقة التحويل"}
        inputType={"file"}
      />
      <DropZoneContainer
        name="PaymentReceiptPhoto"
        accepted="image/*"
        label={"صورة إيصال الدفع"}
        TxtMsg={
          "بعد دفع مصاريف الكلية في ماكينة فوري عبر الكود645 صور صورة منه"
        }
        inputType={"file"}
      />
    </Form>
  );
}

export default UserImagesToUpload;
