import React from "react";
import "../../style/Instructions.css";
import { InstructionsData } from "../../json/Json";
function InstructionContainer() {
  const instructionsView = () => {
    return InstructionsData.map((object, index) => (
      <li key={index}>{object.item}</li>
    ));
  };
  return (
    <div className="InstructionsContainer">
      <span className="infoTxt">
        الأوراق المطلوب التقدم بها من الطلاب الجُدد المرشحين للالتحاق بالكلية
        هذا العام (2022/ 2023م):
      </span>
      <ol type="1" className="orderList">
        {instructionsView()}
      </ol>
      <ul className="unorderList">
        <li> على أن يحتفظ الطالب بصور هذه الأوراق بعد تقديم ملفه</li>
        <li>
          ويقوم الطالب بسداد الرسوم المقررة وقدرها (570.5 ) للانتظام، و( 770.5)
          للانتساب علي ماكينة فوري بالكود (788) بالرقم القومي وتسليم أصل الإيصال
          + 2 صورة منه إلي مكتب شؤون الطلاب
        </li>
      </ul>
    </div>
  );
}

export default InstructionContainer;
