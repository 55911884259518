import React from "react";
import "../../style/logoContainer.css";
import Logo from "../../assets/LogoForHeader.png";
function LogoContainer() {
  return (
    <div className="logoContainer">
      <img src={Logo} alt="logo" />
    </div>
  );
}

export default LogoContainer;
