import React, { useContext, createContext, useState } from "react";
import "../style/layout.css";
import {
  InfoContainer,
  LogoContainer,
  InstructionContainer,
  TogglerContainer,
  UserInfoContainer,
  EditUserInfoContainer,
} from "../components";
const IsSubmitContext = createContext();
export const useIsSubmitContext = () => useContext(IsSubmitContext);
function LayoutContainer() {
  const [isSubmited, setIsSubmited] = useState(false);
  const [isToEdit, setIsToEdit] = useState(false);
  const [userId, setUserID] = useState("");
  const [currentUser, setCurrentUser] = useState({})
  return (
    <IsSubmitContext.Provider
      value={{
        isSubmited,
        setIsSubmited,
        isToEdit,
        setIsToEdit,
        userId,
        setUserID,
        currentUser,
        setCurrentUser,
      }}
    >
      <div className="LayoutContainer">
        <div className="logo_header">
          <LogoContainer />
        </div>
        <div className="info_section">
          <InfoContainer />
        </div>
        <div className="instructions">
          <InstructionContainer />
        </div>
        <div className="navigate_toggler">
          {!isSubmited && <TogglerContainer />}
        </div>
        <div className="add_user_data">
          {isToEdit && <EditUserInfoContainer />}
          {!isToEdit && <UserInfoContainer />}
        </div>
      </div>
    </IsSubmitContext.Provider>
  );
}

export default LayoutContainer;
