import React from "react";
import "../../../../style/PdfFiles.css";
import { usePdfsContext } from "./../UserInfoDataToView";
import Logo from "../../../../assets/LogoForprint.jpg";
function MedicalExamination() {
  const { mediacalData } = usePdfsContext();

  return (
    <div className="pdfContainer" dir="rtl">
      <div className="d-flex justify-content-between pt-4 pb-4">
        <div className="logoContainer">
          {" "}
          <div className="logoContainer">
            <img src={Logo} alt="logo" />
          </div>
        </div>
        <div className="photoContainer">
          {/* صورة شخصية */}
          {mediacalData?.image && (
            <img
              src={`data:image/png;base64,${mediacalData.image}`}
              // src={Img}
              alt="personalImage"
            />
          )}
        </div>
      </div>
      <div className="d-flex mt-3">
        <div className="w-25"></div>
        <div className="d-flex w-75 justify-content-around">
          <div>استمارة توقيع الكشف الطبي</div>
          <div>استمارة رقم: ...............</div>
        </div>
      </div>
      <div className="d-flex mt-3">
        <div className="w-25"></div>
        <div className="d-flex w-75 justify-content-around">
          <div>على الطالب المستجد</div>
          <div> رقم مسلسل: ......................</div>
        </div>
      </div>
      <div className="d-flex mt-3">
        <div className="d-flex w-75 justify-content-between">
          <div>الاسم&nbsp;/&nbsp; {mediacalData?.fullName}</div>
          <div> الكلية &nbsp;/&nbsp; {mediacalData?.facultyName}</div>
        </div>
        <div className="w-50"></div>
      </div>
      <div className="d-flex mt-3">
        <div className="d-flex w-50 justify-content-between">
          <div>العين اليسرى : .................</div>
          <div> بنظارة / بدون نظارة</div>
        </div>
        <div className="w-50"></div>
      </div>
      <div className=" mt-3">قوة الابصار</div>
      <div className="d-flex mt-3">
        <div className="d-flex w-50 justify-content-between">
          <div>العين اليمنى : .................</div>
          <div> بنظارة / بدون نظارة</div>
        </div>
        <div className="w-50"></div>
      </div>
      <div className="d-flex mt-3">
        <div className="d-flex w-75 justify-content-between">
          <div>القلب...................................</div>
          <div>الصدر...................................</div>
        </div>
        <div className="w-50"></div>
      </div>
      <div className="d-flex mt-3">
        <div className="d-flex w-75 justify-content-between">
          <div className="text-center d-flex align-items-center">الفحص</div>
          <div>
            <div className="mb-3">
              الجراحات................................................................................
            </div>
            <div className="mb-3">
              الامراض
              الجلدية...................................................................
            </div>
            <div>
              عاهات
              وتشوهات.................................................................
            </div>
          </div>
        </div>
        {/* <div className="w-50"></div> */}
      </div>
      <div className="d-flex mt-3">
        <div className="d-flex w-100 justify-content-between">
          <div>
            فحص البول....................................................
          </div>
          <div>
            فحوص معملية اخرى.............................................
          </div>
        </div>
        {/* <div className="w-50"></div> */}
      </div>
      <div className="d-flex mt-3">
        <div className="d-flex w-100 justify-content-between">
          <div className="d-flex flex-column">
            <div className="mb-3">طبيب الرمد</div>
            <div>.....................</div>
          </div>
          <div className="d-flex flex-column">
            <div className="mb-3">الطبيب الباطني</div>
            <div>..............................</div>
          </div>
          <div className="d-flex flex-column">
            <div className="mb-3 text-center">الجراح</div>
            <div>...........................</div>
          </div>
        </div>
        {/* <div className="w-50"></div> */}
      </div>
      <div className="d-flex mt-3 flex-column">
        <div className="d-flex w-75 justify-content-between m-auto mb-3">
          <div>اشعة جموعية....................</div>
          <div>فحوص اخرى......................</div>
        </div>
        <div>فحص صدر</div>
        <div className="d-flex w-75 justify-content-between m-auto">
          <div>اشعة بالحجم الطبيعي....................</div>
          <div>اعادة فحص......................</div>
        </div>
        {/* <div className="w-50"></div> */}
      </div>
      <div className="d-flex mt-3 flex-column">
        <div className="d-flex w-100 justify-content-between m-auto mb-3">
          <div>النتيجة....................</div>
          <div className="text-center">اخصائي الصدر</div>
          <div className="text-center">مدير الادارة العامة الطبية</div>
        </div>
        <div className="mb-3">
          تحريرا في &nbsp;&nbsp; / &nbsp; &nbsp;/ &nbsp;&nbsp; 20م
        </div>
        <div className="d-flex w-100 justify-content-between m-auto mb-3">
          <div>اعادةالكشف.................</div>
          <div style={{ marginRight: "-85px" }}>
            ............................
          </div>
          <div>............................</div>
        </div>
        <div className="mb-3">
          الاعادة الاولى..............................{" "}
        </div>
        <div className="d-flex w-100 justify-content-between m-auto mb-3">
          <div>النتيجة....................</div>
          <div className="text-center">الطبيب</div>
          <div className="text-center">مدير الادارة العامة الطبية</div>
        </div>
        <div className="d-flex w-100 justify-content-between m-auto mb-3">
          <div>الاعادة الثانية.................</div>
          <div style={{ marginRight: "-85px" }}>
            ............................
          </div>
          <div>............................</div>
        </div>
        <div className="d-flex w-100 justify-content-between m-auto mb-3">
          <div>النتيجة....................</div>
          <div className="text-center">الطبيب</div>
          <div className="text-center">مدير الادارة العامة الطبية</div>
        </div>
        <div className="d-flex w-100 justify-content-between m-auto mb-3">
          <div>تحريرا في &nbsp;&nbsp; / &nbsp; &nbsp;/ &nbsp;&nbsp; 20م</div>
          <div style={{ marginRight: "-85px" }}>
            ............................
          </div>
          <div>............................</div>
        </div>
        {/* <div className="w-50"></div> */}
      </div>
    </div>
  );
}

export default MedicalExamination;
