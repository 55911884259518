import React, { useRef } from "react";
import ReactToPrint from "react-to-print";
import { FunctionalComponentToPrint } from "./ComponentsToPrint/ComponentToPrint";
import { Button } from "@mui/material";
import "../../../style/UserInfo.css";

function UserInfoSectionLayout({ type, name, toPrint, order }) {
  const Pdf_One = useRef();
  const Pdf_two = useRef();
  return (
    <div className="splittedUserData">
      <div>
        <p>{type}</p>
      </div>
      <div>
        {toPrint && (
          <ReactToPrint
            trigger={() => (
              <Button
                variant="contained"
                color={`${order === 0 ? "info" : "success"}`}
              >
                {name}
              </Button>
            )}
            content={
              order === 0 ? () => Pdf_One.current : () => Pdf_two.current
            }
            // documentTitle="AwesomeFileName"
          />
        )}
        {!toPrint && <p>{name}</p>}
      </div>
      <div style={{ display: "none" }}>
        <FunctionalComponentToPrint ref={Pdf_One} text="test" order={0} />
      </div>
      <div style={{ display: "none" }}>
        <FunctionalComponentToPrint ref={Pdf_two} text="test2" order={1} />
      </div>
      {/* <div ref={componentRef}>test</div> */}
    </div>
  );
}

export default UserInfoSectionLayout;
