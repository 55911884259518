import React from "react";
import "../../../style/UserInfo.css";
import "react-dropzone-uploader/dist/styles.css";
import Form from "react-bootstrap/Form";
import Dropzone from "react-dropzone-uploader";
import { ErrorMessage } from "@hookform/error-message";
import { useFormsContext } from "../formsHookContext/formsHookContext";
import { toast } from "react-toastify";
function DropZoneContainer({ name, label, TxtMsg }) {
  //acceptable
  const readURL = (input) => {
    const fileTypes = ["jpg", "jpeg", "png"];
    if (input) {
      const extension = input.name.split(".").pop().toLowerCase(); //file extension from input file
      const isSuccess = fileTypes.indexOf(extension) > -1; //is extension in acceptable types
      if (isSuccess) {
        const reader = new FileReader();
        reader.onload = (e) => {
          let header = "";
          const arr = new Uint8Array(e.target.result).subarray(0, 4);
          for (let i = 0; i < arr.length; i++) {
            header += arr[i].toString(16);
          }
          function mimeType(headerString) {
            let type = "";
            switch (headerString) {
              case "89504e47":
                type = "png";
                break;
              case "ffd8ffe0":
              case "ffd8ffe1":
              case "ffd8ffe2":
                type = "jpeg";
                break;
              default:
                type = "unknown";
                break;
            }
            return type;
          }
          const isImageValid = fileTypes.indexOf(mimeType(header)) > -1;
          if (!isImageValid) {
            toast.error("only images are valid");
            return;
          } else {
            toast.success("image uploaded successfully");
            setValue(name, input);
          }
        };
        reader.readAsArrayBuffer(input);
      } else {
        toast.warning("only images are valid");
      }
    }
  };
  const { setValue, errors } = useFormsContext();
  const handleChangeStatus = ({ file }) => {
    readURL(file);
  };
  return (
    <div>
      <Form.Label style={{ fontSize: "16px", fontWeight: 600 }}>
        {" "}
        {label}
      </Form.Label>
      <Dropzone
        accepted="image/*"
        maxFiles={1}
        canRemove={true}
        onChangeStatus={handleChangeStatus}
      />
      <ErrorMessage
        errors={errors}
        name={name || ""}
        render={({ message }) => <p className="errorMsg">{message}</p>}
      />
      <span className="textMsg">{TxtMsg}</span>
      <hr />
    </div>
  );
}

export default DropZoneContainer;
