import React from "react";
import MedicalExamination from "./MedicalExamination";
import JoiningRequest from "./JoiningRequest";
/**
 * @param styleToPrint
 * * to print style that will add in the class component
 * const renderStyle = () => {
        return (
        <style type="text/css" media="print">
        {"\
        @page { size: landscape; }\
        "}
        </style>
        );
    };
 */
export class ComponentToPrint extends React.PureComponent {
  setRef = (ref) => (this.canvasEl = ref);

  render() {
    const { order } = this.props;

    return (
      <div className="relativeCSS" dir="rtl">
        {/* <renderStyle /> */}
        <div className="flash" ref={this.setRef} dir="rtl">
          {order === 0 && <MedicalExamination />}
          {order === 1 && <JoiningRequest />}
        </div>
      </div>
    );
  }
}
export const FunctionalComponentToPrint = React.forwardRef((props, ref) => {
  return <ComponentToPrint ref={ref} order={props.order} />;
});
export default ComponentToPrint;
